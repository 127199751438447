import Container from "@material-ui/core/Container"
import React from "react"
import { HeroCarrouselFragment } from "../../__generated__/graphql-gatsby"
import Carousel from "./carousel"

type Data = HeroCarrouselFragment

interface IProps {
  data: Data | undefined | null
}

const HeroCarousel: React.FC<IProps> = ({ data }) => {
  if (!data?.toonCarrousel) return null

  const { achtergrondKleur } = data

  return (
    <Container maxWidth="xl" disableGutters>
      <Carousel
        slides={data.carrousel?.carrousel_fields}
        bg={achtergrondKleur}
      />
    </Container>
  )
}

export default HeroCarousel
