import React from "react"
import {
  OverviewDataFragment,
  wpPageFragment,
} from "../../__generated__/graphql-gatsby"
import HeroCarousel from "../heroCarousel"
import PageBuilder from "../pageBuilder"
import SEO from "../SEO"

interface IProps {
  data: Maybe<wpPageFragment>
  overviewData?: Maybe<Array<Maybe<OverviewDataFragment>>>
  sessionKey: string
}

const PageComponent: React.FC<IProps> = ({
  data,
  overviewData,
  sessionKey,
}) => {
  const { seo, hero_carrousel, page_builder, uri } = { ...data }

  return (
    <>
      <SEO
        title={seo?.title}
        description={seo?.metaDesc}
        ogTitle={seo?.opengraphTitle}
        ogDescription={seo?.opengraphDescription}
        img={{
          url: seo?.opengraphImage?.sourceUrl,
          alt: seo?.opengraphImage?.altText,
          width: seo?.opengraphImage?.mediaDetails?.width,
          height: seo?.opengraphImage?.mediaDetails?.height,
        }}
        siteName={seo?.opengraphSiteName}
        canonical={uri}
      />
      <HeroCarousel data={hero_carrousel} />
      <PageBuilder
        blocks={page_builder?.pageBuilder}
        overviewData={overviewData}
        sessionKey={sessionKey}
      />
    </>
  )
}

export default PageComponent
