import { alpha } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Autoplay from "embla-carousel-autoplay"
import useEmblaCarousel from "embla-carousel-react"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import { borderRadius } from "../../theme/globalStyles"
import { CarrouselFieldsFragment } from "../../__generated__/graphql-gatsby"

interface IProps {
  slides: Maybe<CarrouselFieldsFragment>
  bg?: Maybe<string>
}

type CarouselPositions = "lo" | "lb" | "ro" | "rb" | string

interface IText {
  placement: Maybe<CarouselPositions>
}

const CarouselWrapper = styled.div`
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows[7]};
`

const Container = styled.div`
  display: flex;
  height: 480px;

  .gatsby-image-wrapper {
    height: 480px;
  }
`

const Slide = styled.div`
  position: relative;
  flex: 0 0 100%;
  min-width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
`
const pixels = "30px"
const topLeft = css`
  top: ${pixels};
  left: ${pixels};
`
const topRight = css`
  top: ${pixels};
  right: ${pixels};
`
const bottomLeft = css`
  bottom: ${pixels};
  left: ${pixels};
`
const bottomRight = css`
  bottom: ${pixels};
  right: ${pixels};
`

const Text = styled(({ placement, ...rest }) => (
  <Typography variant="h6" {...rest} />
))<IText>`
  position: absolute;
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(4.5)}px`};
  background-color: ${({ theme }) => alpha(theme.palette.secondary.light, 0.8)};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  max-width: 315px;
  overflow-x: wrap;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  ${borderRadius};
  ${({ placement }) => {
    switch (placement) {
      case "rb":
        return topRight
      case "ro":
        return bottomRight
      case "lo":
        return bottomLeft
      case "lb":
      default:
        return topLeft
    }
  }};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2.5)}px`};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(1.5)}px`};
    font-size: 0.88rem;
    font-weight: 400;
  }
`

const Carousel: React.FC<IProps> = ({ slides, bg }) => {
  const [emblaRef] = useEmblaCarousel(
    {
      loop: true,
      draggable: false,
    },
    [Autoplay({ delay: 8500 })]
  )

  return (
    <CarouselWrapper className="embla" ref={emblaRef}>
      <Container className="embla__container">
        {slides?.items?.map((slide, i) => (
          <Slide className="embla__slide" key={i}>
            <GatsbyImage
              image={
                slide?.item?.afbeelding?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              alt={String(slide?.item?.afbeelding?.altText)}
              loading="eager"
            />
            <Text placement={slide?.item?.locatieTekst}>
              {slide?.item?.tekst}
            </Text>
          </Slide>
        ))}
      </Container>
    </CarouselWrapper>
  )
}

export default Carousel
